<template>
  <!-- 修改密码页面 -->
  <div class="editPassword_wrapper">
    <div class="titleBar" v-if="isApp"></div>

    <div class="pageTitle">
      <div class="title_icon" @click="$router.go(-1)">
        <span class="icon-left"></span>
      </div>
      <div class="title_txt">
        <span>设置</span>
      </div>
    </div>

    <div class="account">
      <div class="account_item">
        <div class="item_label">名字</div>
        <div class="item_content">{{ username }}</div>
      </div>
      <div class="account_item">
        <div class="item_label">用戶名</div>
        <div class="item_content">{{ account }}</div>
      </div>
      <div class="account_item">
        <div class="item_label">角色</div>
        <div class="item_content">{{ type | typeFilter }}</div>
      </div>
      <div class="account_item" v-if="isApp">
        <div class="item_label">消息通知</div>
        <div class="item_content">
          <van-switch v-model="msgRadio" />
        </div>
      </div>
      <div class="account_item" v-show="msgRadio" v-if="isApp">
        <div class="item_label">语音提醒</div>
        <div class="item_content">
          <van-switch v-model="voiceRadio" />
        </div>
      </div>
      <div class="account_item" @click="showEdit = true">
        <div class="item_label">密码修改</div>
        <div class="item_content">
          <span class="icon-right"></span>
        </div>
      </div>
    </div>
    <!-- <div class="logout" @click="logout">
      退 出 登 录
    </div> -->

    <!-- 修改密码区域 -->
    <transition tag="div" name="slide">
      <div class="edit" :class="{ editAPP: isApp }" v-show="showEdit">
        <div class="edit_box">
          <div class="edit_item">
            <div class="item_label">旧密码</div>
            <div class="item_content">
              <input
                v-model="password"
                type="password"
                placeholder="请输入旧密码"
                οninput="if(value.length>6)value=value.slice(0,16)"
              />
            </div>
          </div>
          <div class="edit_item">
            <div class="item_label">新密码</div>
            <div class="item_content">
              <input
                v-model="newPassword"
                type="password"
                placeholder="请输入新密码"
                οninput="if(value.length>6)value=value.slice(0,16)"
              />
            </div>
          </div>
          <div class="edit_item">
            <div class="item_label">重复密码</div>
            <div class="item_content">
              <input
                v-model="doublePassword"
                type="password"
                placeholder="请再次输入密码"
                οninput="if(value.length>6)value=value.slice(0,16)"
              />
            </div>
          </div>
        </div>

        <div class="edit_btn">
          <div class="btn_item cancel" @click="showEdit = false">
            取 消
          </div>
          <div class="btn_item confirm" @click="editPassword">
            确 定
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Dialog } from "vant";
export default {
  data: () => ({
    showEdit: false, //修改密码弹出层
    password: "", //旧密码
    newPassword: "", //新密码
    doublePassword: "", //再次输入新密码
    msgRadio: true,
    voiceRadio: true
  }),
  methods: {
    /**
     * 表单清空
     */
    formInit() {
      this.password = "";
      this.newPassword = "";
      this.doublePassword = "";
    },

    /**
     * 修改密码
     */
    editPassword() {
      //必须都填
      if (this.password && this.newPassword && this.doublePassword) {
        // if (this.password == this.newPassword) {
        //   Dialog.alert({
        //     title: "系统提示",
        //     message: "不能与旧密码相同"
        //   });
        //   return;
        // }
        //两次新密码不一致
        if (this.newPassword != this.doublePassword) {
          Dialog.alert({
            title: "系统提示",
            message: "两次输入密码不一致"
          });
        } else {
          if (this.newPassword.length < 6) {
            Dialog.alert({
              title: "系统提示",
              message: "密码长度为6至16位"
            });
            return;
          }
          this.$tools.showLoading();

          // this.$post(this.$config.EMPLOYEE_UPDATE_PASSWORD, {
          //   lastPassword: this.$md5(this.password),
          //   newPassword: this.$md5(this.newPassword)
          // })
          this.$api
            .updatePassword({
              lastPassword: this.$md5(this.password),
              newPassword: this.$md5(this.newPassword)
            })
            .then(() => {
              //清空表单
              this.formInit();

              Dialog.alert({
                title: "系统提示",
                message: "修改密码成功，请重新登录"
              }).then(() => {
                this.$db.remove("_a");
                this.$db.remove("_b");
                this.$router.replace({
                  name: "Login"
                });
              });

              this.$tools.closeLoading();
            });
        }
      } else if (!this.password) {
        Dialog.alert({
          title: "系统提示",
          message: "请输入您的旧密码"
        });
      } else if (!this.newPassword) {
        Dialog.alert({
          title: "系统提示",
          message: "请输入您的新密码"
        });
      } else if (!this.doublePassword) {
        Dialog.alert({
          title: "系统提示",
          message: "请再次输入您的新密码"
        });
      }
    }

    // logout() {
    //   this.$jsBridge.logout();
    //   this.$router.push({ name: "Login" });
    // },
  },

  computed: {
    ...mapState({
      username: state => state.account.username,
      account: state => state.account.account,
      type: state => state.account.type,
      isApp: state => state.isApp
    })
  },

  watch: {
    password() {
      this.password = this.password.replace(/[^\w]/g, "");
    },
    newPassword() {
      this.newPassword = this.newPassword.replace(/[^\w]/g, "");
    },
    doublePassword() {
      this.doublePassword = this.doublePassword.replace(/[^\w]/g, "");
    },
    showEdit(newV) {
      if (newV) {
        this.formInit();
      }
    },

    msgRadio(newV) {
      if (newV) {
        this.voiceRadio = true;
      }
      let obj = {
        notify: newV ? "1" : "0",
        voice: this.voiceRadio ? "1" : "0"
      };
      this.$jsBridge.syncNotifyMode(obj);
      this.$db.save("MSG_MODE", JSON.stringify(obj));
    },

    voiceRadio(newV) {
      let obj = {
        notify: this.msgRadio ? "1" : "0",
        voice: newV ? "1" : "0"
      };
      this.$jsBridge.syncNotifyMode(obj);
      this.$db.save("MSG_MODE", JSON.stringify(obj));
    }
  },

  mounted() {
    let msgMode = JSON.parse(
      this.$db.get("MSG_MODE", "") || this.$config.msgMode
    );
    this.msgRadio = msgMode["notify"] == "1" ? true : false;
    this.voiceRadio = msgMode["voice"] == "1" ? true : false;
    console.log("msgMode ==> ", msgMode);
  }
};
</script>

<style lang="less" scoped>
.slide-enter-to,
.slide-leave {
  transform: translateX(0);
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s;
}

.editPassword_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.account {
  flex: 1;
  width: 100%;
  padding: 0 14px;

  .account_item {
    height: 60px;
    border-bottom: 1px solid rgba(7, 17, 27, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;

    .item_label {
      color: #333;
    }

    .item_content {
      color: #000;
    }
  }
}

.logout {
  width: 100%;
  height: 50px;
  background-color: #063390;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.edit {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  height: calc(100% - 50px);
  background-color: #fff;
  // padding-top: ;

  display: flex;
  flex-direction: column;
  z-index: 49;

  &.editAPP {
    top: 74px;

    height: calc(100% - 74px);
  }

  .edit_box {
    flex: 1;
    padding: 0 14px;
  }

  .edit_item {
    height: 60px;
    border-bottom: 1px solid rgba(7, 17, 27, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;

    .item_label {
    }

    .item_content {
      flex: 0 0 200px;

      input {
        width: 100%;
        border: 0;
        outline: 0;
        // border-bottom : 1px solid #333 ;
      }
    }
  }
}

.edit_btn {
  height: 50px;
  display: flex;

  .btn_item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #fff;

    &.confirm {
      background-color: #063390;
    }

    &.cancel {
      background-color: #666;
    }
  }
}
</style>
